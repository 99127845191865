<script setup lang="ts">
  import { JobDetailsSource, JobPreview, JobSimple } from '@borg/types';

  defineProps<{
    job: JobPreview | JobSimple;
    source?: JobDetailsSource;
  }>();

  // currently there's a BE bug which sometimes fills the externailDetailsUrl field with a random hash,
  // so we need to check on frontend if it's a valid URL
  const VALID_URLS = ['poslovac.hr', 'sezonac.hr'];
</script>

<template>
  <LinksLocale
    v-if="
      job.externalDetailsUrl &&
      job.externalDetailsUrl.length &&
      VALID_URLS.some((url) => job.externalDetailsUrl?.includes(url))
    "
    :to="job.externalDetailsUrl"
    target="_blank"
    external
  >
    <slot />
  </LinksLocale>
  <LinksLocale
    v-else
    :to="getJobLocation(job.id, job.slug, { source })"
  >
    <slot />
  </LinksLocale>
</template>
